/* LoadingAnimation.css */
  
  .dot-container {
    display: flex;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #97C751; /* 원하는 색상으로 설정 */
    animation: fadeInOut 1s infinite; /* fadeInOut 애니메이션을 1초 간격으로 무한 반복 */
  }
  
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; } /* 시작과 끝에서 점이 사라짐 */
    50% { opacity: 1; } /* 중간에 점이 나타남 */
  }
  